import React, { useState, useEffect, useCallback } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Modal from 'react-bootstrap/Modal';
import patchCertificates from '../VenafiServiceCalls/patchCertificates.js';
import getAG from '../VenafiServiceCalls/getAssignmentGroups.js';
import {animatedConfirmbox,animatedSuccessAlert} from "../SweetAlertItem/SweetAlert";

function AdminModal(props) {
   const [assignmentGroup, setAssignmentGroup] = useState();
   const [existingAG, setExistingAG] = useState();
   const [assignmentGroupList, setAssignmentGroupList] = useState([]);
   const [isDisabled, setIsDisabled] = useState(true);
   const [disabledMessage] = useState('Please fill out all the fields.');
   const [slack, setSlack] = useState('');
   const [distributionList, setDistirbutionList] = useState('');
   const[notes, setNotes] = useState('');

   const checkDisable = useCallback(() => {
      console.log("length", props.row.length);
      (slack || distributionList || assignmentGroup) ? setIsDisabled(false) : setIsDisabled(false);
      // (!slack || !distributionList || !existingAG || !assignmentGroup || !notes) ? setIsDisabled(true) : setIsDisabled(false);
   }, [props, slack, distributionList, assignmentGroup]);

   useEffect(() => {
      const interval = setInterval(() => {
         checkDisable();
      }, 800);
      getAG(props.access_token).then((res) => {
         setAssignmentGroupList(res.body);
      });
      return () => clearInterval(interval);
   }, [props, checkDisable]);

   useEffect(() => {
      (props.row.slack_details && props.row.slack_details.slackChannel) ? setSlack(props.row.slack_details.slackChannel) : setSlack('');
      (props.row.contact_details && props.row.contact_details.distributionList) ? setDistirbutionList(props.row.contact_details.distributionList) : setDistirbutionList('');
      (props.row.snow_details && props.row.snow_details.assignmentGroup) ? setExistingAG(props.row.snow_details.assignmentGroup) : setExistingAG('');
      console.log(props.row);
      localStorage.removeItem('errorCount');
      (props.row.notes && props.row.notes) ? setNotes(props.row.notes) : setNotes('');
   }, [props]);

   const handleSlackChange = (event) => {
      checkDisable();
      setSlack(event.target.value);
   };
   const handleDistChange = (event) => {
      checkDisable();
      setDistirbutionList(event.target.value);
   };
   const handleNotesChange = (event) => {
      checkDisable();
      setNotes(event.target.value);
   }

   // code to update single and multiple records.
   const submitForm = () => {
      let selectedItems = (Array.isArray(props.row)) ? props.row : [props.row];
      const alertMessage= 'You have selected more than 100 records, Are you sure to update?';
      //(selectedItems.length > 1) ? submitData(selectedItems): submitExpiryData(selectedItems);
      (selectedItems.length > 100) ? animatedConfirmbox(alertMessage,selectedItems,submitData) : submitData(selectedItems);
   }

   let submitData = async (selectedItems)=>
      {
         props.onHide();
         props.setLoading(true);
         for (let item in selectedItems) {
            let rowNum = selectedItems[item];
            const data = {
               cn: rowNum.cn,
               user: props.user,
               valid_to: rowNum.valid_to,
               assignmentGroup: assignmentGroup ? assignmentGroup : " ",
               slackChannel: slack ? slack : " ",
               distributionList: distributionList ? distributionList : " ",
               notes: notes ? notes:"Edited by user "+props.user,
            };
            console.log("submit:", data);
            let res=await patchCertificates(props.access_token, data);
            console.log('patch ', JSON.stringify(res));
         }
         clearForm();
         props.getRes();
         console.log("props getRes", props.getRes());
         props.setLoading(false);
         //animatedSuccessAlert('Certificate details updated!');
      }

   const clearForm = () => {
      setSlack('');
      setDistirbutionList('');
      setNotes('');
   };

   return (
      <>
         <Modal
            {...props}
            size="lg"
            centered
            aria-labelledby="contained-modal-title-vcenter"
         >
            <Modal.Header closeButton>
              { <Modal.Title id="contained-modal-title-vcenter">
                  Update Certificate: {props.row.cert_name} <br />
                  Certificate CN: {props.row.cn} <br />
               </Modal.Title>}
            </Modal.Header>
            <Modal.Body>
               <div>
                  <div>
                     <Autocomplete
                        id="assignmentGroup"
                        options={assignmentGroupList}
                        value={assignmentGroup}
                        getOptionLabel={(option) => option}
                        onChange={(e, newValue) => {
                           setAssignmentGroup(newValue);
                        }}
                        inputValue={existingAG}
                        onInputChange={(e, newInputValue) =>
                           setExistingAG(newInputValue)
                        }
                        style={{ width: 300 }}
                        required
                        renderInput={(params) => (
                           <TextField
                              {...params}
                              label="Assignment Groups"
                              variant="outlined"
                           />
                        )}
                     />
                  </div>
                  <br />
                  <br />
                  <div>
                     <TextField
                        id="slack"
                        label="Slack Group"
                        variant="outlined"
                        value={slack}
                        onChange={handleSlackChange}
                        autoComplete="off"
                     />
                  </div>
                  <span className="text-color-accent">
                     Note: Private channel requires @Certificate Bot to be added
                     in your channel
                  </span>
                  <br />
                  <br />
                  <div>
                     <TextField
                        id="distribution-list"
                        label="Distribution List"
                        variant="outlined"
                        value={distributionList}
                        onChange={handleDistChange}
                        autoComplete="off"
                     />
                  </div>
                  <span className="text-color-accent">
                     Note: for multiple distribution List(user mailid @nike.com) should separated by "," 
                  </span>
                  <br />
                  <br />
                  <div>
                     <TextField
                        id="notes"
                        label="Notes"
                        variant="outlined"
                        value={notes}
                        onChange={handleNotesChange}
                        autoComplete="off"
                     />
                  </div>
                  <span className="text-color-accent">
                     Note: Don't have a note? Add renewal instructions.
                  </span>
                  <br />
                  <br />
               </div>
            </Modal.Body>
            <Modal.Footer>
               <div>
                  <button
                     className="ncss-btn-secondary-dark"
                     onClick={props.onHide}
                  >
                     Close
                  </button>
               </div>

               <div>
                  <button
                     className="ncss-btn-primary-dark"
                     onClick={submitForm}
                     disabled={isDisabled}
                  >
                     Submit
                  </button>
               </div>
            </Modal.Footer>
            {isDisabled && (
               <span
                  className="text-color-error pr2-sm"
                  style={{ textAlign: 'right' }}
               >
                  {disabledMessage}
               </span>
            )}
         </Modal>
      </>
   );
}

export default AdminModal;
